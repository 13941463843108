export default defineNuxtRouteMiddleware((to) => {
  const user = useSupabaseUser();

  // Non logged user
  if (!user?.value) {
    if (to.path === "/") {
      return;
    }

    if (to.path === "/login") {
      return;
    }

    if (to.path === "/sign-up") {
      return;
    }

    if (to.path === "/sign-up-confirmation") {
      return;
    }

    if (to.meta.layout === "guest") {
      return;
    }

    return navigateTo("/login");
  }

  // Logged user
  if (to.path === "/login" || to.path === "/sign-up") {
    return navigateTo("/dashboard/poll");
  }

  return;
});
