import { default as _91id_93zL1rs6jTJvMeta } from "/vercel/path0/talento-callejero/pages/[id].vue?macro=true";
import { default as indexOa2M4D5DUyMeta } from "/vercel/path0/talento-callejero/pages/dashboard/mp-setup/index.vue?macro=true";
import { default as indexyuxcHG6NFgMeta } from "/vercel/path0/talento-callejero/pages/dashboard/payment/index.vue?macro=true";
import { default as indexSpSmIgBq5TMeta } from "/vercel/path0/talento-callejero/pages/dashboard/poll/index.vue?macro=true";
import { default as indexU7hrnXl2wwMeta } from "/vercel/path0/talento-callejero/pages/dashboard/profile/index.vue?macro=true";
import { default as indexc5JE99dbdeMeta } from "/vercel/path0/talento-callejero/pages/dashboard/qr-code/index.vue?macro=true";
import { default as indexkiUUVX4UQKMeta } from "/vercel/path0/talento-callejero/pages/dashboard/settings/index.vue?macro=true";
import { default as index1mzenn8JkDMeta } from "/vercel/path0/talento-callejero/pages/index.vue?macro=true";
import { default as indexdws3wHWKFMMeta } from "/vercel/path0/talento-callejero/pages/login-magic/index.vue?macro=true";
import { default as indexTIh5HkwQ0oMeta } from "/vercel/path0/talento-callejero/pages/login/index.vue?macro=true";
import { default as indexuupl5DmVtWMeta } from "/vercel/path0/talento-callejero/pages/otp-redirect/index.vue?macro=true";
import { default as indexCJWsEUGQJeMeta } from "/vercel/path0/talento-callejero/pages/otp-sent/index.vue?macro=true";
import { default as indexatFIeGEul8Meta } from "/vercel/path0/talento-callejero/pages/sign-up-confirmation/index.vue?macro=true";
import { default as waitforloginlvmlhzBN0UMeta } from "/vercel/path0/talento-callejero/pages/sign-up-confirmation/waitforlogin.vue?macro=true";
import { default as indexgBDTQxxoxyMeta } from "/vercel/path0/talento-callejero/pages/sign-up/index.vue?macro=true";
import { default as indexWCz9P7oIcBMeta } from "/vercel/path0/talento-callejero/pages/songs/index.vue?macro=true";
export default [
  {
    name: "id",
    path: "/:id()",
    meta: _91id_93zL1rs6jTJvMeta || {},
    component: () => import("/vercel/path0/talento-callejero/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mp-setup",
    path: "/dashboard/mp-setup",
    component: () => import("/vercel/path0/talento-callejero/pages/dashboard/mp-setup/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-payment",
    path: "/dashboard/payment",
    component: () => import("/vercel/path0/talento-callejero/pages/dashboard/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-poll",
    path: "/dashboard/poll",
    component: () => import("/vercel/path0/talento-callejero/pages/dashboard/poll/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile",
    path: "/dashboard/profile",
    component: () => import("/vercel/path0/talento-callejero/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-qr-code",
    path: "/dashboard/qr-code",
    component: () => import("/vercel/path0/talento-callejero/pages/dashboard/qr-code/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    component: () => import("/vercel/path0/talento-callejero/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index1mzenn8JkDMeta || {},
    component: () => import("/vercel/path0/talento-callejero/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-magic",
    path: "/login-magic",
    component: () => import("/vercel/path0/talento-callejero/pages/login-magic/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/talento-callejero/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "otp-redirect",
    path: "/otp-redirect",
    component: () => import("/vercel/path0/talento-callejero/pages/otp-redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "otp-sent",
    path: "/otp-sent",
    component: () => import("/vercel/path0/talento-callejero/pages/otp-sent/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-confirmation",
    path: "/sign-up-confirmation",
    component: () => import("/vercel/path0/talento-callejero/pages/sign-up-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-confirmation-waitforlogin",
    path: "/sign-up-confirmation/waitforlogin",
    component: () => import("/vercel/path0/talento-callejero/pages/sign-up-confirmation/waitforlogin.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/vercel/path0/talento-callejero/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "songs",
    path: "/songs",
    component: () => import("/vercel/path0/talento-callejero/pages/songs/index.vue").then(m => m.default || m)
  }
]