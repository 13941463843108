import { defineNuxtPlugin } from "#app";
import posthog from "posthog-js";

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  if (import.meta.env.MODE === "development") {
    return {
      provide: {
        posthog: () => {
          return {
            capture: (param) => {console.log('Mock Posthog capture', param)},
            identify: (param) => {console.log('Mock Posthog identify', param)},
            reset: (param) => {console.log('Mock Posthog reset', param)},
          };
        },
      },
    };
  }


  const posthogClient = posthog.init(runtimeConfig.public.POSTHOG_PUBLIC_KEY, {
    api_host: runtimeConfig.public.POSTHOG_HOST || "https://us.i.posthog.com",
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog) => {
      if (import.meta.env.MODE === "development") posthog.debug();
    },
  });

  // Make sure that pageviews are captured with each route change
  const router = useRouter();
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture("$pageview", {
        current_url: to.fullPath,
      });
    });
  });

  return {
    provide: {
      posthog: () => posthogClient,
    },
  };
});
