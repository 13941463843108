import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations(env) {
  if (env !== "production") {
    return;
  }

  import("@sentry/browser").then((lazyLoadedSentry) => {
    Sentry.addIntegration(
      lazyLoadedSentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      })
    );
  });
}

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const config = useRuntimeConfig();

    if (config.public.ENV !== "production") {
      return;
      /*      return {
        provide: {
          Sentry: () => {
            return {
              captureException: (exception) => {
                console.log("Mock Sentry captureException", exception);
              },
              setUser: (params) => {
                console.log("Mock Sentry setUser", params);
              },
            };
          },
        },
      }; */
    }

    const vueApp = nuxtApp.vueApp;
    // const router = useRouter();

    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
      // integrations: [Sentry.browserTracingIntegration({ router })], */
      integrations: (integrations) => {
        // integrations will be all default integrations
        return integrations.filter((integration) => {
          return integration.name !== "Dedupe";
        });
      },
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE,
      environment: config.public.ENV,
    });

    lazyLoadSentryIntegrations(config.public.ENV);

    return {
      provide: {
        Sentry,
      },
    };
  },
});
