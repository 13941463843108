export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("fix-dropdown-click", {
    mounted(element) {
      element.addEventListener("click", () => {
        const elem = document.activeElement;
        if (elem) {
          elem?.blur();
        }
      });
    },
  });
});
